// Here you can add other styles
.c-sidebar {
  background: #153b62;
  .c-sidebar-brand {
    background: #fff;
    margin-bottom: 1em;
    align-items: center;
    justify-content: end;
  }
}
.c-active {
  background: #d45723 !important;
  color: white;
  font-weight: 500;
}

.custom-control-input {
  color: #fff;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: #2eb85c !important;
  background-color: #2eb85c !important;
}

.container-fluid {
  padding-bottom: 30px;
  margin-left: -0.5em;
}

.nav-link {
  color: #ef5f24;
}

.nav-link:active {
  color: #ef5f24;
  border-color: #ef5f24;
}

.bg-dark-blue {
  background: #153b62 !important;
}

.bg-dark-green {
  background: #4dbd74 !important;
}

.btn-logout {
  color: white;
  background: #ef5f24;
}

.dashboard-content {
  min-height: 48rem;
  min-height: 100vh;
}

.connectivity-alert {
  text-align: center;
  padding: 0.5rem;
  color: $pure-white;
  background-color: #e63600;
}

.question-content {
  padding-right: 1.25rem;
}

.navigation-item {
  text-align: left;
  text-transform: uppercase;

  .navigation-item__link {
    padding: 0.6rem 0rem;
    line-height: 1.45rem;
    letter-spacing: 0.065rem;
    display: block;

    span {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .fake-border {
      width: 80%;
    }

    &.active,
    &:hover {
      background-color: $primary-orange;
      border-bottom: none;

      span {
        color: $pure-white;
      }

      &:after {
        border-color: $deeper-orange;
      }
    }

    &.label {
      border-bottom: 1px solid white;
      padding-right: 1rem;
      text-align: center;
      &:hover {
        background-color: transparent;
        border-bottom: 1px solid white;
      }
    }
  }

  &.non-link {
    padding: 0.6rem 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 0.065rem;
    display: block;

    &:hover {
      cursor: pointer;

      span {
        color: $deeper-orange;
      }
    }
  }
}

.top-bar {
  display: flex;
  align-items: baseline;
  .icon-links {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
    .icon {
      width: auto;
      height: 16px;

      &:first-child {
        margin-left: 0.756rem;
      }
    }
  }

  .top-bar-navigation {
    .top-bar-navigation-item {
      display: inline-block;
      margin: 0px 1rem;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.questionnaire-container {
  padding: 2rem 2rem 5rem;

  .saving-indicator {
    position: fixed;
    top: 0px;
    left: 50%;
    background-color: $primary-orange;
    padding: 0.35rem 2rem 0.55rem;
    box-shadow: 0px 0px 9px #e2e2e2, 0px 2px 5px #efefef;
    color: $pure-white;
    z-index: 10;
    letter-spacing: 1px;
  }
}

.question-list {
  list-style: decimal;

  &.multiple_questions {
    margin-left: 1rem;
  }

  &__item {
    padding-bottom: 1rem;

    &__question {
      padding-bottom: 0.2rem;
    }
  }
}

.table-wrapper {
  overflow-y: scroll;
}

.table {
  width: 100%;

  .input-field {
    label {
      display: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  &.table-stripped {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    background-color: $pure-white;

    thead {
      tr {
        th {
          padding: 1rem 0.5rem;
          text-align: left;
          border-top: 3px solid $near-black;
          border-bottom: 2px solid $near-black;
          text-transform: uppercase;
          letter-spacing: 0.45px;
          font-weight: 600;
          color: $near-black;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem 0.5rem;
          text-align: left;
          border-bottom: 1px solid $light-grey;
          font-size: 0.975rem;
        }
      }
    }
  }
}

.responses-container,
.cdr-banner,
.pre-loader {
  padding: 4px 2px;
}

.response-sidebar {
  border-right: 2px solid rgba(242, 95, 3, 0.2);
  background: #153b62;
  padding: 0;
  .navigation-item {
    text-align: left;
    text-transform: none !important;

    .navigation-item__link {
      padding: 0.6rem 1rem;
      line-height: 1.25rem;
      letter-spacing: 0rem !important;
      display: block;
      font-size: 1rem;
      color: $pure-white;

      // span {
      //   // padding-right: 1rem;
      //   // padding-left: 1rem;
      // }

      .fake-border {
        width: 80%;
      }

      &.active,
      &:hover {
        background-color: $primary-orange;
        color: $pure-white;
        border-bottom: none;
        cursor: pointer;

        span {
          color: $pure-white;
        }

        &:after {
          border-color: $deeper-orange;
        }
      }
    }
  }
}

.questions-container {
  padding-left: 2rem;
  border-radius: 1em;
  .question-title {
    margin-left: -0.9rem;
    border-bottom: 1px solid $primary-orange;
    padding-bottom: 0.25rem;
    margin-bottom: 0.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .question-list {
    list-style: decimal;

    &.multiple_questions {
      margin-left: 1rem;
    }

    &__item {
      padding-bottom: 1rem;

      &__question {
        padding-bottom: 0.2rem;
        padding-top: 0.4rem !important;
        font-size: 1rem;

        p {
          margin: 0px;
        }
      }
    }
  }

  .table-wrapper {
    overflow-y: scroll;
    margin-top: 0.5rem;
  }
}

.field-answer {
  color: $primary-orange;
}

.form {
  .Dropdown-arrow {
    border-width: 8px 6px 0;
    border-color: $primary-orange transparent transparent;
  }

  .action-bar {
    padding-top: 0.5rem;

    button:first-child {
      margin-right: 1rem;
    }
  }

  .section-progress {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;

    .progress-bar {
      background-color: $light-grey;
      height: 5px;
      margin-bottom: 2rem;
      margin-top: 0.4rem;

      .inner-bar {
        display: block;
        height: 5px;
      }
    }
  }
}

.connectivity-box {
  position: fixed;
  width: 100%;
  z-index: 100;

  .retry-link {
    font-size: 0.864rem;
    margin-left: 0.54rem;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

.field-action-button {
  border-radius: 0px;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  color: $primary-orange;
  font-weight: 600;
  margin-top: 0.5rem;
  letter-spacing: 2px;

  &:hover {
    background-color: $primary-orange !important;
    color: $pure-white !important;
  }
}

.question-title {
  padding: 0.75rem;
  margin-bottom: 2.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
}

.mandatory-explanation {
  margin-left: -1.5rem;
  position: relative;
  bottom: 0.5rem;
}

.intro-banner {
  padding: 0.5rem 0.25rem;
  margin-bottom: 1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  .question-title {
    margin: 0px;
    margin-bottom: 1rem;
  }

  .cdr-table {
    border-collapse: collapse;

    thead {
      tr {
        th {
          padding: 1rem;
          text-align: left;
          border: 2px solid #f9c35e;
          color: $primary-blue;
          letter-spacing: 1px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 1rem;
          text-align: left;
          border: 2px solid #f9c35e;
          margin: 0px;
        }
      }
    }
  }

  .score-card {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0px;
    }

    td.question-points {
      padding: 0.5rem 1rem;

      .small {
        padding-bottom: 0.65rem;
      }

      border-right: unset;
    }

    td.explanation-section {
      padding: 0px;

      thead {
        tr {
          th {
            padding: 1rem;
            text-align: left;
            font-weight: 100;
            border: 2px solid #f9c35e;
            color: $primary-blue;
            letter-spacing: 1px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 1rem;
            text-align: left;
            border: 2px solid #f9c35e;
            margin: 0px;
          }
        }
      }
    }
  }

  &.why-cdr {
    .explanation {
      margin-bottom: 1.2rem;
      font-size: 0.95rem;

      .emphasis {
        font-size: 0.9rem;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .sub-title {
      margin-bottom: 1rem;
    }
  }

  &.performance {
    .explanation {
      margin-bottom: 0.65rem;
      font-size: 0.95rem;
    }
  }

  &.eight-indicators {
    .explanation {
      font-size: 0.95rem;
    }

    .normal__smaller {
      font-size: 0.95rem;
    }
  }
  &.profile-section {
    display: flex;
    .name-card {
      background: aliceblue;
      font-size: larger;
      padding: 1em;
      border-radius: 1em;
      margin-right: 0.5rem;
      .name {
        display: block;
        color: gray;
        font-size: 0.9em;
        padding-bottom: 1em;
      }
    }
    .details-card {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: aliceblue;
      border-radius: 1em;
      padding: 1em;
      .name {
        display: block;
        color: gray;
        font-size: 0.9em;
        padding-bottom: 0.7em;
        padding-top: 0.7em;
      }
    }
  }
}

.cdr-next-btn {
  margin-left: -21px;
}

.no-child-padding {
  & > span {
    padding: 0px !important;
  }
}
