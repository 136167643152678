// Variable overrides
$pure-white: #ffffff;
$almost-white: #f9f9f9;
$near-white: #f7f7f7;
$light-grey: #d4d4d4;
$primary-blue: #153b62;
$light-blue: #15648a;
$primary-orange: #f06024;
$deeper-orange: #fd5c18;
$light-orange: #f9711c;
$near-black: #191919;
$alert-red: rgb(228, 83, 39);
$dark-grey: #363636;
$secondary-black: #201a16;
